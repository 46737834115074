import React, { useState } from 'react';

import * as styles from './OAuth.module.scss';
import TbankButton from '../Buttons/TbankButton/TbankButton';

export default function OAuth() {
  const [appHost, setAppHost] = useState(process.env.APP_HOST);

  const handleTbankCkick = () => {
    window.location = `https://${appHost}/auth/tinkoff_id`;
  };

  return (
    <div className={styles.OAuth}>
      <h3 className={styles.OAuth__header}>
        Вход в личный кабинет
      </h3>
      <div className={styles.OAuth__rowSpacing}>
        <TbankButton onClick={handleTbankCkick} />
      </div>
    </div>
  );
};
